import ApplicationController from '../application_controller';
import {showEntri} from 'entrijs';

export default class Text extends ApplicationController {
  // rename this to export default class Domain extends ApplicationController
  static targets = ['hiddenField', 'text', 'subdomainInput', 'domainInput', 'validationMessage', 'customDomainValidationMessage'];

  static values = {
    domain: String
  }

  initialize() {
    this.timeout = null;
    this.debounceDelay = 300;
    this.entri = 'https://cdn.goentri.com/entri.js';
    window.addEventListener('onEntriClose', this.handleOnEntriClose);
  };

  updateDasherySubdomainPreview(event) {
    const subdomain = this.inputSanitize(event.target.value);

    // Update spans to display the new domain name
    this.textTargets.forEach((span) => {
      span.innerText = `${subdomain}.${this.domainValue}`;
    });

    // Update hidden field for form submission
    this.hiddenFieldTarget.value = subdomain;

    // Update the input field value to reflect the clean input
    this.subdomainInputTarget.value = subdomain;

    if (subdomain !== "") {
      this.checkSubdomain(subdomain);
    } else {
      this.invalidInput('Please enter a valid domain name.');
    }
  };

  checkSubdomain(value) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      $.post('/account/check_dashery_subdomain_availability', {subdomain: value}, (response) => {
        this.handleSubdomainResponse(response);
      });
    }, this.debounceDelay);
  };

  inputSanitize(input, allowDot) {
    input = input.toLowerCase();
    const rejectedCharsCount = this.countRejectedRegexFromStart(input)

    if (rejectedCharsCount > 0) {
      // reject chars from front of input
      input = input.slice(rejectedCharsCount);
    };

    if (allowDot == true) {
      return input.replace(/[^a-z0-9._\-~]/g, '');
    } else {
      return input.replace(/[^a-z0-9_\-~]/g, '');
    };
  }

  countRejectedRegexFromStart(input) {
    let count = 0
    for (let i = 0; i < input.length; i++) {
      if (/[^a-z0-9]/.test(input[i])) {
          count++;
      } else {
          break;
      }
    }
    return count
  }

  handleSubdomainResponse(response) {
    switch (response) {
      case 'valid':
        this.validInput();
        break;
      case 'taken':
        this.invalidInput('Domain not available.');
        break;
      default:
        this.invalidInput(response);
    };
  };

  validInput() {
    this.validationMessageTarget.innerText = 'Domain available.';
    this.validationMessageTarget.classList.add("m-account-input__status--valid");
    this.validationMessageTarget.classList.remove("m-account-input__status--invalid");
    document.querySelector('.jsDialogComponentWrapper .jsDialogSwitch.btn.btn--large.btn--full').disabled = false;
    document.querySelector('.m-account-section .tp-form .tp-input-field--wrapper ').classList.remove('tp-input-field--text-error');
    document.querySelector('.m-account-section .tp-form .tp-input-field--wrapper ').classList.add('tp-input-field--text-success');
  };

  invalidInput(innerText) {
    this.validationMessageTarget.innerText = innerText
    this.validationMessageTarget.classList.add("m-account-input__status--invalid");
    this.validationMessageTarget.classList.remove("m-account-input__status--valid");
    document.querySelector('.jsDialogComponentWrapper .jsDialogSwitch.btn.btn--large.btn--full').disabled = true;
    document.querySelector('.m-account-section .tp-form .tp-input-field--wrapper ').classList.remove('tp-input-field--text-success');
    document.querySelector('.m-account-section .tp-form .tp-input-field--wrapper ').classList.add('tp-input-field--text-error');
  };

  handleCustomDomainInput(event) {
    const customDomain = this.inputSanitize(event.target.value, true);
    const button = document.querySelector('#custom-domain-entri-connect-btn');
    const customDomainInputDiv = document.querySelector('#custom-domain-input').parentElement.parentElement;
    document.querySelector('#m-account-section__text_note').classList.remove('hidden');
    button.disabled = true;

    this.domainInputTarget.value = customDomain;

    if (customDomain !== "") {
      this.checkCustomDomain(customDomain, button);
    } else {
      customDomainInputDiv.classList.remove('tp-input-field--text-success', 'tp-input-field--text-error');
      this.customDomainValidationMessageTarget.innerText = '';
    }
  }

  checkCustomDomain(customDomain, button) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      $.post('/account/check_dashery_custom_domain_availability', {customDomain}, (response) => {
        this.handleCustomDomainCheckResponse(response, button)
      });
    }, this.debounceDelay);
  };

  handleCustomDomainCheckResponse(response, button) {
    const customDomainInputDiv = document.querySelector('#custom-domain-input').parentElement.parentElement;
    if (response.valid === true) {
      button.disabled = false;
      customDomainInputDiv.classList.remove('tp-input-field--text-error');
      customDomainInputDiv.classList.add('tp-input-field--text-success');
      this.customDomainValidationMessageTarget.innerText = ''
    } else {
      customDomainInputDiv.classList.remove('tp-input-field--text-success');
      customDomainInputDiv.classList.add('tp-input-field--text-error');
      this.customDomainValidationMessageTarget.innerText = response.error;
    };
  };

  handleSetupWithEnti() {
    const customDomain = document.querySelector('#custom-domain-input').value
    $.get('/account/connect_entri_config', {customDomain}, (response) => {
      if (response.config) {
        this.handleEntriCall(response.config);
      } else {
        window.location.href = '/account/dashery_url';
      }
    });
  }

  handleEntriCall(config) {
    showEntri(config)
      .catch( error => {
        const customDomainInputDiv = document.querySelector('#custom-domain-input').parentElement.parentElement;
        document.querySelector('#custom-domain-entri-connect-btn').disabled = true;
        this.customDomainValidationMessageTarget.innerText = error.message;
        customDomainInputDiv.classList.remove('tp-input-field--text-success');
        customDomainInputDiv.classList.add('tp-input-field--text-error');
      })
  }

  handleOnEntriClose(event) {
    if (event.detail.success === true) {
      document.querySelector('#custom-domain-input').disabled = true
      document.querySelector('#custom-domain-entri-connect-btn').disabled = true;
      $.post('/account/add_dashery_custom_domain', {customDomain: event.detail.domain }, (response) => {
        if (response.success === true) {
          window.location.href = '/account/dashery_url';
        } else {
          document.querySelector('#domain-validation-message').innerText = response.message;
          document.querySelector('#custom-domain-entri-connect-btn').disabled = false;
          document.querySelector('#custom-domain-input').disabled = false;
        };
      });
    } else {
      document.querySelector('#domain-validation-message').innerText = "Entri connect was unsuccessful, please try again.";
    };
  }
}
